import React from 'react'
import Layout from '../components/layout'
import WritingPage from '../components/WritingPage'
// Assets

const McDonalds = () => (
    <Layout currentPage='/writing'>
        <WritingPage
                    Title = "McDonalds"
                    Caption = ""
                    Collaborators ="
                    Jose Lemus"
                    MainText ="In high school I began my first job as a cashier at McDonalds. Currently editing.
                    "
                    Date="Spring 2015"
        />
    </Layout>
)

export default McDonalds;